/**
 * Parallax Backdrop
 *
 * Based on https://github.com/jscottsmith/react-scroll-parallax/blob/develop/src/components/ParallaxBanner.js
 */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useEventListener } from '../../util/hooks';
import testForPassiveScroll from '../../util/passive-scroll';

import {
	StyledParallaxBackdrop,
	StyledParallaxContainer,
	StyledParallaxLayer,
	StyledParallaxImage,
} from './styled-parallax-backdrop';

// Passive scroll listener support
const supportsPassive = testForPassiveScroll();

const absoluteStyle = {
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
};

const innerStyle = {
	//whiteSpace: 'nowrap',
};

const containerStyle = {

	height: '100%',
};

const ParallaxBackdrop = ({
	children,
	className,
	layers,
	scrollerRef,
	disabled = false,
}) => {
	const viewportWidth = useRef(0);
	const scrollWidth = useRef(0);
	const scrollX = useRef(0);
	const [scroll, setScroll] = useState(0);
	const [scrollMaths, setScrollMaths] = useState({total: 0, clamp: 0});

	// update scroll state to trigger rerender
	const updateParallax = () => {
		//console.log(scrollX.current, scrollMaths);
		setScroll(scrollX.current / scrollMaths.total);
	};

	const updateMaths = () => {
		const total = scrollWidth.current - viewportWidth.current;
		//console.log('setting scrollMaths...', scrollX.current);
		setScrollMaths({
			// dividing by this amount provides the percentage of total scroll
			total,
			// multiplying this with scroll clamps transform percent so right edge meets end of container
			clamp: total / viewportWidth.current,
		});
	};

	useEffect(() => {
		updateParallax();
	}, [scrollMaths]);

	const scrollHandler = (e) => {
		scrollX.current = e.currentTarget.scrollLeft;

		//console.log(scrollX.current, '/', e.currentTarget.scrollWidth - e.currentTarget.offsetWidth);

		//requestAnimationFrame(updateParallax);
		updateParallax();
	};

	const resizeHandler = () => {
		viewportWidth.current = scrollerRef.current.offsetWidth;
		scrollWidth.current = scrollerRef.current.scrollWidth;

		//console.log('v', viewportWidth.current, 's', scrollWidth.current);

		//requestAnimationFrame(updateMaths);
		updateMaths();
	};

	useEventListener('scroll', scrollHandler, scrollerRef.current, supportsPassive ? { passive: true } : false);
	useEventListener('resize', resizeHandler, window, false);

	// initialize
	useEffect(() => {
		resizeHandler();
	}, []);

	return (
		<StyledParallaxBackdrop
			className={'parallax-backdrop' + (className ? ` ${className}` : '')}
		>
				{layers.map(
					(
						{
							amount,
							children,
							expanded = false,
							image,
							props = {},
						},
						i
					) => {
						// save props to be merged
						const layerStyle = props.style || {};
						const layerClass = props.className || '';

						// remove from pass through props
						delete props.style;
						delete props.className;

						const layerClassMerged = `parallax-layer-${i}${
							layerClass ? ` ${layerClass}` : ''
						}`;

						//console.log(`${i}:`, (scroll * (amount + amount)) - amount);

						const left = -(scroll * scrollMaths.clamp)
						             //+ ((scroll * (amount + amount)) - amount)
									 //+ (scroll * scrollMaths.clamp * amount)
						             * 100;

						return (
							<StyledParallaxLayer
								key={i}
								className={layerClassMerged}
								style={{
									transform: `translate3d(${left}%, 0, 0)`
								}}
							>
								{image ? (
									<StyledParallaxImage
										src={image}
										alt=""
									/>
								) : null}
								{children}
							</StyledParallaxLayer>
						);
					}
				)}
			{children}
		</StyledParallaxBackdrop>
	);
};

export default ParallaxBackdrop;
