/**
 * Styled Scene
 */

import styled from 'styled-components';

export const StyledSceneContainer = styled.div`
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 133.3333%;
`;

export const StyledScene = styled.div``;
/*
	box-sizing: border-box;
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	font-size: 10rem;
`;*/

export const StyledSceneBackdrop = styled.div`
	position: absolute;
	z-index: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;
