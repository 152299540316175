/**
 * Day
 *
 * DayAsset fallback import based on https://stackoverflow.com/a/48264153
 */

import React, { useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import Image from '../image';

import { useDayContext } from '../../context/day-context';

import './index.scss';

const DayAsset = ({day, ...rest}) => {
	return (
		<Image
			src={`img/day/${day}.png`}
			srcOnError={`img/day/0.svg`}
			{...rest}
		/>
	);
};

const Description = ({ info }) => {
	return (
		<div class="day-info">
			<p class="day-info__text">{info}</p>
		</div>
	);
};

const Day = ({ dayNumber, info, ...rest }) => {
	const [active, setActive] = useState(false);
	const [startingCoords, setStartingCoords] = useState({});
	const [endingCoords, setEndingCoords] = useState({});
	const dayRef = useRef();
	const maxDay = useDayContext();

	const onClick = () => {
		setActive(!active);
	};

	const cacheStartingCoords = () => {
		const { top, left, width, height } = dayRef.current.getBoundingClientRect();
		setStartingCoords({
			top,
			left,
			width,
			height,
		});
		console.log({startingCoords, endingCoords});
	};
	const activateEndingCoords = () => {
		setEndingCoords({
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
		});
		console.log({startingCoords, endingCoords});
	};
	const clearCoords = () => {
		setStartingCoords({});
		setEndingCoords({});
	};

	/**
	 * These have to be in JS because the starting position needs to be set via
	 * JS in the style attr. The endingCoords state is being set after the
	 * starting coords so that there is a tick for it to kick in and let CSS
	 * handle the transition. Using CSSTransition instead of Transition gives us
	 * the forced re-render inbetween.
	 */
	const transitionStyles = {
		entering: {
			position: 'fixed',
			zIndex: 999,
			...endingCoords,
		},
		entered: {
			position: 'fixed',
			zIndex: 999,
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
		},
		exiting: {
			position: 'fixed',
			zIndex: 999,
			...startingCoords,
		},
		exited: {
			// return to default state
		},
	};

	return (
		<CSSTransition
			in={active}
			classNames="day"
			timeout={500}
			onEnter={cacheStartingCoords}
			onEntering={activateEndingCoords}
			onExited={clearCoords}
			nodeRef={dayRef}
		>
			{(state) => {console.log(state, startingCoords); return (
				<div
					className={`day ${active ? 'active' : ''}`}
					onClick={onClick}
					style={{...(state === 'entering' ? startingCoords : {}), ...transitionStyles[state]}}
					ref={dayRef}
				>
					<div className="day__asset">
						<DayAsset day={dayNumber <= maxDay ? dayNumber : 0} {...rest} />
					</div>
					<Description info={info} />
				</div>
			)}}
		</CSSTransition>
	);
};

export default Day;
