/**
 * Test for passive scroll
 *
 * From https://github.com/jscottsmith/react-scroll-parallax/blob/402babc6a62ac84f5973b1c6af4025b18b732b67/src/utils/testForPassiveScroll.js
 */

export default function testForPassiveScroll() {
	let supportsPassiveOption = false;
	try {
		const opts = Object.defineProperty({}, 'passive', {
			get() {
				supportsPassiveOption = true;
			},
		});
		window.addEventListener('test', null, opts);
		window.removeEventListener('test', null, opts);
	} catch (e) {}
	return supportsPassiveOption;
}
