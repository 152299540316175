/**
 * Scene
 */

import React from 'react';

import SceneCard from '../scene-card';

import { StyledSceneContainer, StyledScene, StyledSceneBackdrop } from './styled-scene';

export default function Scene({
	sceneIndex,
	backdrop,
	items, // door components
	sceneDimensions, // used to calculate item position
	sliderRef,
}) {
	const { w: sceneW, h: sceneH } = sceneDimensions;
	return (
		<StyledSceneContainer
			data-scene={sceneIndex}
			key={sceneIndex}
		>
			<StyledSceneBackdrop>
				{backdrop}
			</StyledSceneBackdrop>
			<StyledScene>
				{items ? items.map((item, i) => {
					const { x, y, w, h } = item;
					return (
						<SceneCard
							key={i}
							top={`${y/sceneH*100}%`}
							left={`${x/sceneW*100}%`}
							width={`${w/sceneW*100}%`}
							height={`${h/sceneH*100}%`}
							sliderRef={sliderRef}
							{...item}
						/>
					);
				}) : null}
			</StyledScene>
		</StyledSceneContainer>
	);
};
