/**
 * Scroller
 */

import React, { useEffect, useRef } from 'react';
import Snowfall from 'react-snowfall';
import { useWindowWidth } from '@react-hook/window-size';

import Slider from '../slider';
import Scene from '../scene';

import backdropData from '../../data/backdrop-data';
import sceneData from '../../data/scene-data';

import './index.css';

const sceneDimensions = {w: 768, h: 1024};

function SceneController({ goToScene }) {
	//const [scene, setScene] = useState(1); // scenes are 1-indexed
	const sliderRef = useRef();
	const windowWidth = useWindowWidth({ leading: true });

	useEffect(() => {
		if (sliderRef.current && goToScene !== undefined && goToScene > 0 && goToScene <= 5) {
			sliderRef.current.scrollToSlide(goToScene);
		}
	}, [goToScene, sliderRef]);

	const mapWindowWidthToSnowflakeCount = () => {
		if (windowWidth > 920) {
			return 150;
		}
		if (windowWidth > 480) {
			return 75;
		}
		return 50;
	};

	return (
		<Slider
			slidesPerPageSettings={{
				mobileSmall: 1,
				mobileBig: 2,
				tablet: 2,
				desktop: 3,
			}}
			ref={sliderRef}
			renderForeground={windowWidth > 0 ? (
				<Snowfall
					snowflakeCount={mapWindowWidthToSnowflakeCount()}
				/>
		) : null}
			backdropLayers={backdropData}
			backgroundColor="#c7cfe9"
		>
			{sceneData.map((item, index) => {
				return (
					<Scene
						key={`scene-${index}`}
						sceneIndex={index}
						sceneDimensions={sceneDimensions}
						sliderRef={sliderRef}
						{...item}
					>
						{index}
					</Scene>
				);
			})}
		</Slider>
	);
}

export default SceneController;
