/**
 * Date context
 */

import { createContext, useContext } from 'react';

/**
 * DayContext
 *
 * Integer from 1-25, calculated based on current date.
 *
 * @todo date stuff, should default to 0 if before or 25 if after!
 */
const DayContext = createContext(0);
DayContext.displayName = 'DayContext';

/**
 * Custom context hook for DayContext value
 *
 * @since [version]
 * @return int DayContext (1-25)
 */
function useDayContext() {
	const context = useContext(DayContext);
	if (context === undefined) {
		console.error('DayContext not provided');
		return 0;
	}
	return context;
}

export { DayContext, useDayContext };
