/**
 * Image component with fallback on error, onLoad event, and placeholder
 */

import React, { useEffect, useState } from 'react';

export default function ImageComponent({
	src,
	srcOnError,
	renderPlaceholder = () => null,
	onLoad = (src) => {},
	onError = (err) => {},
	alt = '',
	...rest
}) {
	const [imgSrc, setImgSrc] = useState(src);
	const [imageIsLoaded, setImageIsLoaded] = useState(false);

	// Load image
	useEffect(() => {
		const image = new Image();
		image.onload = () => {
			setImageIsLoaded(true);
			onLoad(imgSrc);
		};
		image.onerror = (err) => {
			if (srcOnError) setImgSrc(srcOnError);
			onError(err);
		};
		image.src = imgSrc;

		return () => {
			image.onLoad = null;
			image.onError = null;
		}
	}, [imgSrc, onLoad, onError, srcOnError]);

	if (imageIsLoaded) {
		return <img src={imgSrc} alt={alt} {...rest} />;
	}

	return renderPlaceholder();
}
