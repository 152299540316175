/**
 * Slide
 *
 * Based on https://github.com/lifarl/react-scroll-snap-slider/blob/master/src/components/Slide/index.tsx
 */

import React from 'react';
import { StyledSlide } from './styled-slide';

const Slide = React.forwardRef(
	(
		{ slideIndex, slidesPerPageSettings, slideWidth, children },
		ref
	) => {
		return (
			<StyledSlide
				slidesPerPageSettings={slidesPerPageSettings}
				slideWidth={slideWidth}
				data-index-number={slideIndex}
				key={slideIndex}
				ref={ref}
			>
				{children}
			</StyledSlide>
		);
	}
);

Slide.displayName = 'Slide';

export default Slide;
