/**
 * Styled Slide
 *
 * Based on https://github.com/lifarl/react-scroll-snap-slider/blob/master/src/components/Slide/Slide.styled.ts
 */

import styled from 'styled-components';

export const StyledSlide = styled.div`
	scroll-snap-align: center;
	display: flex;
	justify-content: center;
	min-width: ${(props) =>
		props.slidesPerPageSettings
			? `${100 / props.slidesPerPageSettings.mobileSmall}%`
			: props.slideWidth
				? `${props.slideWidth}px`
				: '100%'};
	@media (min-width: 512px) {
		${(props) =>
			props.slidesPerPageSettings
				? `min-width: ${100 / props.slidesPerPageSettings.mobileBig}%`
				: ''};
	}
	@media (min-width: 753px) {
		${(props) =>
			props.slidesPerPageSettings
				? `min-width: ${100 / props.slidesPerPageSettings.tablet}%`
				: ''};
	}
	@media (min-width: 1232px) {
		${(props) =>
			props.slidesPerPageSettings
				? `min-width: ${100 / props.slidesPerPageSettings.desktop}%`
				: ''};
	}
`;
