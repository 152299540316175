/**
 * Styled Slider
 *
 * Based on https://github.com/lifarl/react-scroll-snap-slider/blob/master/src/components/Carousel/Carousel.styled.ts
 */

import styled from 'styled-components'

export const StyledSliderContainer = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	overflow: hidden;
	background-color: ${(props) => props.backgroundColor ?? 'transparent'};
`;

export const StyledSlider = styled.div`
	::-webkit-scrollbar {
		display: none;
	}
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: none;
	scrollbar-width: none;
	scroll-snap-type: x mandatory;
	scroll-behavior: smooth;
	overflow-x: scroll;
	overflow-y: hidden;
	width: 100%;
`;

export const StyledSliderList = styled.div`
	position: relative;
	z-index: 1;
	display: flex;
	flex-wrap: nowrap;
	list-style: none;
	width: 100%;
	padding: 0;
	margin: 0;
	white-space: nowrap;
`;

export const StyledSliderForeground = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 3;
	pointer-events: none;
	overflow: hidden;
`;
