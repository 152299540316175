/**
 * Intersection Observer
 *
 * Based on https://github.com/lifarl/react-scroll-snap-slider/blob/master/src/utils/intersectionObserver.ts
 */

export const getObserver = (
	root = null,
	ref,
	callback,
	threshold
) => {
	const observer = ref.current;
	if (observer !== null) {
		return observer;
	}
	const newObserver = new IntersectionObserver(callback, {
		root,
		rootMargin: '0px',
		threshold: threshold,
	});
	ref.current = newObserver;
	return newObserver;
}
