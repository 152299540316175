
import bgClouds1 from '../assets/backdrop/bg-clouds-1.svg';
import bgClouds2 from '../assets/backdrop/bg-clouds-2.svg';
import bgClouds3 from '../assets/backdrop/bg-clouds-3.svg';
import fgClouds from '../assets/backdrop/fg-clouds.svg';
import hills from '../assets/backdrop/hills.svg';
import skyline from '../assets/backdrop/skyline.svg';
import snow from '../assets/backdrop/snow.svg';

const backdropData = [
	{
		image: bgClouds1,
		amount: 1,
	},
	{
		image: bgClouds2,
		amount: 0.88,
	},
	{
		image: bgClouds3,
		amount: 0.85,
	},
	{
		children: (
			<div className="clouds">
				<img src={fgClouds} alt="" />
				<img src={fgClouds} alt="" />
			</div>
		),
		amount: 0.65,
	},
	{
		image: hills,
		amount: 0.5,
	},
	{
		image: skyline,
		amount: 0.2,
	},
	{
		image: snow,
		amount: 0,
	},
];

export default backdropData;
