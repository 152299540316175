
import { ReactComponent as Scene1SVG } from '../assets/scene-1.svg';
import { ReactComponent as Scene2SVG } from '../assets/scene-2.svg';
import { ReactComponent as Scene3SVG } from '../assets/scene-3.svg';
import { ReactComponent as Scene4SVG } from '../assets/scene-4.svg';
import { ReactComponent as Scene5SVG } from '../assets/scene-5.svg';

const sceneData = [
	{
		backdrop: <Scene1SVG />,
		items: [
			{
				dayNumber: 1,
				x: 42.402,
				y: 251.5157,
				w: 200.5876,
				h: 200.5876,
				peelColor: '#fbebde',
				alt: 'a decorated tree',
				info: 'Check out your local town\'s holiday display',
			},
			{
				dayNumber: 2,
				x: 283.7062,
				y: 251.5157,
				w: 200.5876,
				h: 200.5876,
				peelColor: '#fbebde',
				alt: 'a holiday card',
				info: 'Send a holiday card to friends and family',
			},
			{
				dayNumber: 3,
				x: 525.0104,
				y: 251.5157,
				w: 200.5876,
				h: 200.5876,
				peelColor: '#fbebde',
				alt: '',
				info: 'Take a peek at some storefront holiday window displays',
			},
			{
				dayNumber: 4,
				x: 283.7062,
				y: 527.0485,
				w: 200.5876,
				h: 200.5876,
				peelColor: '#4d6c6e',
				alt: 'gingerbread house candy decorations',
				info: 'Decorate a gingerbread house',
			},
			{
				dayNumber: 5,
				x: 283.7062,
				y: 742.6574,
				w: 200.5876,
				h: 200.5876,
				peelColor: '#4d6c6e',
				alt: '',
				info: 'Watch your favorite holiday movie',
			},
		],
	},
	{
		backdrop: <Scene2SVG />,
		items: [
			{
				dayNumber: 6,
				x: 200.8733,
				y: 18.6755,
				w: 200.5876,
				h: 200.5876,
				peelColor: '#fbebde',
				alt: '',
				info: 'Make popcorn garland for the birds',
			},
			{
				dayNumber: 7,
				x: 366.8811,
				y: 302.6216,
				w: 200.5876,
				h: 200.5876,
				peelColor: '#cd5858',
				alt: '',
				info: 'Print out and color a holiday-themed coloring page',
			},
			{
				dayNumber: 8,
				x: 200.8733,
				y: 539.1249,
				w: 200.5876,
				h: 200.5876,
				peelColor: '#cd5858',
				alt: '',
				info: 'Send a letter to Santa',
			},
			{
				dayNumber: 9,
				x: 200.8733,
				y: 760.9038,
				w: 200.5876,
				h: 200.5876,
				peelColor: '#cd5858',
				alt: '',
				info: 'Donate to your local pet shelter',
			},
			{
				dayNumber: 10,
				x: 467.1748,
				y: 760.9038,
				w: 200.5876,
				h: 200.5876,
				peelColor: '#cd5858',
				alt: '',
				info: 'Check out your neighborhood\'s holiday lights and decorations',
			},
		],
	},
	{
		backdrop: <Scene3SVG />,
		items: [
			{
				dayNumber: 11,
				x: 86.0091,
				y: 136.746,
				w: 200.5876,
				h: 200.5876,
				peelColor: '#cd5858',
				alt: '',
				info: 'Bake some cookies',
			},
			{
				dayNumber: 12,
				x: 310.9086,
				y: 236.8145,
				w: 200.5876,
				h: 200.5876,
				peelColor: '#cd5858',
				alt: '',
				info: 'Make some hot chocolate',
			},
			{
				dayNumber: 13,
				x: 74.8669,
				y: 791.142,
				w: 200.5876,
				h: 200.5876,
				peelColor: '#4d6c6e',
				alt: '',
				info: 'Decorate a holiday wreath for your front door',
			},
			{
				dayNumber: 14,
				x: 305.225,
				y: 791.142,
				w: 200.5876,
				h: 200.5876,
				peelColor: '#4d6c6e',
				alt: '',
				info: 'Donate to your local food bank',
			},
			{
				dayNumber: 15,
				x: 495.4043,
				y: 571.3311,
				w: 200.5876,
				h: 200.5876,
				peelColor: '#4d6c6e',
				alt: '',
				info: 'Build a snowman',
			},
		],
	},
	{
		backdrop: <Scene4SVG />,
		items: [
			{
				dayNumber: 16,
				x: 104.2798,
				y: 233.3948,
				w: 200.5876,
				h: 200.5876,
				peelColor: '#4d6c6e',
				alt: '',
				info: 'Reach out to someone you haven\'t seen in a while',
			},
			{
				dayNumber: 17,
				x: 345.584,
				y: 233.3948,
				w: 200.5876,
				h: 200.5876,
				peelColor: '#4d6c6e',
				alt: '',
				info: 'Make an ornament for your tree'
			},
			{
				dayNumber: 18,
				x: 104.2798,
				y: 533.312,
				w: 200.5876,
				h: 200.5876,
				peelColor: '#cd5858',
				alt: '',
				info: 'Cut out paper snowflakes',
			},
			{
				dayNumber: 19,
				x: 345.584,
				y: 533.312,
				w: 200.5876,
				h: 200.5876,
				peelColor: '#cd5858',
				alt: '',
				info: 'Send thank you notes or gifts to your local healthcare workers, police, or firemen',
			},
			{
				dayNumber: 20,
				x: 345.821,
				y: 757.9266,
				w: 200.5876,
				h: 200.5876,
				peelColor: '#cd5858',
				alt: '',
				info: 'Make a pillow fort',
			},
		],
	},
	{
		backdrop: <Scene5SVG />,
		items: [
			{
				dayNumber: 21,
				x: 35.7098,
				y: 368.173,
				w: 200.5876,
				h: 200.5876,
				peelColor: '#4d6c6e',
				alt: '',
				info: 'Go for a winter hike at your local park',
			},
			{
				dayNumber: 22,
				x: 338.7244,
				y: 368.173,
				w: 200.5876,
				h: 200.5876,
				peelColor: '#4d6c6e',
				alt: '',
				info: 'Donate to a local homeless shelter',
			},
			{
				dayNumber: 23,
				x: 46.0732,
				y: 713.8291,
				w: 200.5876,
				h: 200.5876,
				peelColor: '#4d6c6e',
				alt: '',
				info: 'Start a new family tradition',
			},
			{
				dayNumber: 24,
				x: 290.0469,
				y: 714.6738,
				w: 200.5876,
				h: 200.5876,
				peelColor: '#4d6c6e',
				alt: '',
				info: 'Cook a new recipe',
			},
			{
				dayNumber: 25,
				x: 533.0206,
				y: 591.2552,
				w: 200.5876,
				h: 200.5876,
				peelColor: '#4d6c6e',
				alt: '',
				info: 'Have a safe and happy holiday celebration',
			},
		],
	},
];

export default sceneData;
