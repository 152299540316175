/**
 * Slider
 *
 * Based on https://github.com/lifarl/react-scroll-snap-slider/blob/master/src/components/Carousel/index.tsx
 */

import React, { Children, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import Slide from '../slide';
import NavArrow from '../nav-arrow';
import ParallaxBackdrop from '../parallax-backdrop';
import { getObserver } from '../../util/intersection-observer';
import {
	StyledSliderContainer,
	StyledSliderForeground,
	StyledSlider,
	StyledSliderList
} from './styled-slider';

const Slider = forwardRef(({
	renderCustomArrow,
	renderForeground,
	backdropLayers,
	backgroundColor,
	slidesPerPageSettings,
	slideWidth,
	onScrollStart,
	onScrollEnd,
	onSlidesVisibilityChange,
	onSlideVisible,
	children,
}, ref) => {
	const [isScrolling, setIsScrolling] = useState(false);
	const scrollTimeout = useRef(null);
	const sliderRef = useRef(null);
	const slideRefs = useRef([]);
	const arrowPrevRef = useRef(null);
	const arrowNextRef = useRef(null);
	const observer = useRef(null);
	const lastVisibleSlideIndex = useRef(0);
	const medianVisibleSlideIndex = useRef(0);
	const visibleSlidesIndices = useRef([]);
	const intersectionThreshold = 0.5;
	const hideArrowThreshold = 30;

	const addNode = useCallback((node, index) => {
		slideRefs.current[index] = node
	}, []);

	const getSlideWidth = useCallback(() => (sliderRef.current?.firstChild?.firstChild)?.clientWidth || 0, []);

	const intersectionCallback = useCallback((entries) => {
		entries.forEach((entry) => {
			const target = entry.target;
			const index = Number(target.dataset.indexNumber);

			if (entry.intersectionRatio >= intersectionThreshold) {
				lastVisibleSlideIndex.current = index;
				visibleSlidesIndices.current.push(index);
				visibleSlidesIndices.current.sort();

				slideRefs.current[index].setAttribute('aria-hidden', 'false');

				onSlideVisible && onSlideVisible(index);

				return;
			}

			visibleSlidesIndices.current = visibleSlidesIndices.current.filter(item => item !== index);
			slideRefs.current[index].setAttribute('aria-hidden', 'true');
		})

		medianVisibleSlideIndex.current = visibleSlidesIndices.current[Math.floor(visibleSlidesIndices.current.length / 2)];

		onSlidesVisibilityChange && onSlidesVisibilityChange(medianVisibleSlideIndex.current);
	}, []);

	const isSliderScrollable = useCallback(() => {
		if (!sliderRef.current) return false;

		const sliderWidth = sliderRef.current.clientWidth;
		const slideWidth = getSlideWidth() - 1; // - 1 is to make sure that fractional widths don't count

		return slideRefs.current.length * slideWidth > sliderWidth;
	}, []);

	/**
	 * @param {string} direction 'prev' | 'next'
	 */
	const manualScroll = (direction) => {
		const dir = direction === 'prev' ? -1 : 1
		if (sliderRef.current) {
			const slideWidth = getSlideWidth();
			const slidesToScroll = Math.floor(
				sliderRef.current.clientWidth / (slideWidth + 1) // add 1 to force snapping to each slide
			);
			const scrollpx = (slidesToScroll * slideWidth * dir) - dir; // the removal of 1px helps snap when moving backwards
			sliderRef.current.scrollBy({
				top: 0,
				behavior: 'smooth',
				left: scrollpx,
			});
		}
	};

	const onSliderScroll = () => {
		scrollTimeout.current && clearTimeout(scrollTimeout.current);
		scrollTimeout.current = setTimeout(() => {
			scrollTimeout.current = null;
			setIsScrolling(false);
			onScrollEnd && onScrollEnd(medianVisibleSlideIndex.current);
		}, 250);

		if (!isScrolling) {
			setIsScrolling(true);
		}
	};

	const scrollTo = useCallback((left) => {
		if (!sliderRef.current) return;

		sliderRef.current.scrollTo({
			top: 0,
			behavior: 'smooth',
			left,
		});
	}, []);

	const scrollToSlide = useCallback((index) => {
		if (!sliderRef.current) return;

		const sliderScrollLeft = sliderRef.current.scrollLeft;
		const sliderWidth = sliderRef.current.clientWidth;
		const slideWidth = getSlideWidth();
		const slideLeft = slideWidth * index;

		// only scroll to the left if target slide is outside of view to the left
		if (slideLeft < sliderScrollLeft) {
			scrollTo(slideLeft);
			return;
		}

		// only scroll to the right if target slide is outside of view to the right
		if (slideLeft + slideWidth > sliderScrollLeft + sliderWidth) {
			scrollTo(slideLeft + slideWidth - sliderWidth);
		}
	}, []);

	useImperativeHandle(ref, () => ({
		scrollToSlide,
		get el() {
			return sliderRef.current;
		},
	}))

	// intersection observer
	useEffect(() => {
		if (observer.current) observer.current.disconnect();
		const newObserver = getObserver(
			sliderRef.current,
			observer,
			intersectionCallback,
			intersectionThreshold
		);
		for (const node of slideRefs.current) {
			if (node) {
				newObserver.observe(node);
			}
		}
		return () => newObserver.disconnect();
	}, [slideRefs.current.length]);

	// onScrollStart callback
	useEffect(() => {
		if (!isScrolling) return;

		onScrollStart && onScrollStart(medianVisibleSlideIndex.current);
	}, [isScrolling]);

	// nav arrow visibility
	useEffect(() => {
		if (!isSliderScrollable()) return

		if (!sliderRef.current || !arrowNextRef.current || !arrowPrevRef.current) return

		/*
		if (isScrolling) {
			arrowNextRef.current.style.display = 'none';
			arrowPrevRef.current.style.display = 'none';

			return;
		}
		*/

		if (sliderRef.current.scrollLeft <= hideArrowThreshold) {
			arrowNextRef.current.style.display = 'block';
			arrowPrevRef.current.style.display = 'none';
		}
		else if (
			sliderRef.current.clientWidth + sliderRef.current.scrollLeft >=
			sliderRef.current.scrollWidth - hideArrowThreshold
		) {
			arrowPrevRef.current.style.display = 'block';
			arrowNextRef.current.style.display = 'none';
		}
		else {
			arrowNextRef.current.style.display = 'block';
			arrowPrevRef.current.style.display = 'block';
		}
	}, [slideRefs.current.length, isScrolling]);

	return (
		<StyledSliderContainer backgroundColor={backgroundColor}>

			{renderCustomArrow ? (
				<>
					{renderCustomArrow({
						direction: 'prev',
						ref: arrowPrevRef,
						onClick: manualScroll,
					})}
					{renderCustomArrow({
						direction: 'next',
						ref: arrowNextRef,
						onClick: manualScroll,
					})}
				</>
			) : (
				<>
					<NavArrow
						ref={arrowPrevRef}
						direction={'prev'}
						onClick={() => manualScroll('prev')}
					/>
					<NavArrow
						ref={arrowNextRef}
						direction={'next'}
						onClick={() => manualScroll('next')}
					/>
				</>
			)}

			<StyledSlider
				onScroll={onSliderScroll}
				ref={sliderRef}
			>
				{/* StyledSliderList must be the first child of StyledSlider */}
				<StyledSliderList>
					{Children.map(children, (child, index) => (
						<Slide
							key={index}
							slideIndex={index}
							slidesPerPageSettings={slidesPerPageSettings}
							slideWidth={slideWidth}
							ref={(node) => addNode(node, index)}
						>
							{child}
						</Slide>
					))}
				</StyledSliderList>
				{backdropLayers ? (
					<ParallaxBackdrop
						layers={backdropLayers}
						scrollerRef={sliderRef}
					/>
				) : null}
				<StyledSliderForeground>
					{renderForeground}
				</StyledSliderForeground>
			</StyledSlider>
		</StyledSliderContainer>
	)
})

Slider.displayName = 'Slider';

export default Slider;
