import React from 'react';

import dayjs from 'dayjs';

import SceneController from '../scene-controller';
import { DayContext } from '../../context/day-context';

import './index.css';

function App() {
	const date = dayjs();
	const day = date.isBefore('2020-12-01')
		? 0
		: date.isAfter('2020-12-25')
			? 25
			: date.date();

	return (
		<DayContext.Provider value={day}>
			<div className="app">
				<SceneController />
				{/*
				<div className="dayLabel">
					Day: { day }
				</div>
				*/}
			</div>
		</DayContext.Provider>
	);
}

export default App;
