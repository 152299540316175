/**
 * Styled Parallax Backdrop
 */

import styled from 'styled-components';

export const StyledParallaxBackdrop = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	min-width: 100%;
	height: 100%;
	overflow: hidden;
`;

export const StyledParallaxContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	min-width: 100%;
	height: 100%;
	overflow: hidden;
`;

export const StyledParallaxLayer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
`;

export const StyledParallaxImage = styled.img`
	display: block;
	position: relative;
	height: 100%;
	width: auto;
`;
