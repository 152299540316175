/**
 * Scene Door
 */

import React, { useEffect, useRef, useState } from 'react';
import Reward from 'react-rewards';
import useSound from 'use-sound';

import { useDayContext } from '../../context/day-context';

import Image from '../image';
import Day from '../day';

import '../../vendor/peel.css';
import Peel from '../../vendor/peel.js';

import './index.scss';

const Card = ({day, onLoad, ...rest}) => {
	return (
		<Image
			src={`img/card/${day}.svg`}
			srcOnError={`img/card/0.svg`}
			onLoad={onLoad}
			{...rest}
		/>
	);
};

export default function SceneCard({
	dayNumber,
	top,
	left,
	width,
	height,
	peelColor,
	alt,
	info,
	sliderRef,
}) {
	const [cardIsLoaded, setCardIsLoaded] = useState(false);
	const [open, setOpen] = useState(false);
	const maxDay = useDayContext();
	const cardRef = useRef();
	const peelRef = useRef();
	const rewardRef = useRef();
	const [jingleRate, setJingleRate] = useState(1);
	const [jingle] = useSound(
		'sound/jingle.mp3',
		{
			volume: 0.15,
			playbackRate: jingleRate,
		}
	);
	/*
	const [peel, {stopPeel}] = useSound(
		'sound/peel.mp3',
		{ volume: 0.15 }
	);
	*/

	useEffect(() => {
		if (cardRef.current && sliderRef.current) {
			// cleanup previous peel instance
			if (peelRef.current?.removeEvents) {
				peelRef.current.removeEvents();
				peelRef.current = null;
			}
			var p = peelRef.current = new Peel(cardRef.current, {
				scrollElement: sliderRef.current.el,
			});
			p.setFadeThreshold(0.8);
			var started = false;
			p.handleDrag(function(evt, x, y) {
				// if fully pulled off p is set to null but drag event continues
				if (open) return;

				// set corner based on where user touched
				if (!started) {
					const el = this;
					this.setCorner(x >= el.width/2 ? el.width : 0, y >= el.height/2 ? el.height : 0);
					started = true;
				}
				this.setPeelPosition(x, y);
				if (p.getAmountClipped() >= 0.9) {
					// hide top layer & shadow effects once opened
					//this.topLayer.style.display = 'none';
					//this.bottomShadowElement.style.display = 'none';
					p.removeEvents();
					setOpen(true);
				}
			});

			return () => {
				peelRef.current?.removeEvents();
			}
		}
	}, [cardRef.current, sliderRef.current]);

	useEffect(() => {
		if (open && rewardRef.current) {
			rewardRef.current.rewardMe();
			jingle();
			setJingleRate(1 + ((Math.random() - 0.5) * 0.5));
		}
	}, [open]);

	if (dayNumber <= maxDay) {
		return (
			<div
				className={`scene-card peel ${cardIsLoaded ? 'loaded' : ''} ${open ? 'open' : ''}`}
				style={{top, left, width, height}}
				ref={cardRef}
			>
				<Reward
					ref={(ref) => rewardRef.current = ref}
					type="emoji"
					config={{
						emoji: ['🎄','🎁','🎅','🤶','🍾','🍫','❤️','❄️','⛄️','🌨','☃️','🧣','🧤','🥶'],
						spread: 200,
						springAnimation: false,
						zIndex: 5,
					}}
				>
					<div className="peel-top">
						<Card
							day={dayNumber}
							onLoad={() => setCardIsLoaded(true)}
						/>
					</div>
					<div className="peel-back" style={{backgroundColor: peelColor}} />
					<div className="peel-bottom" style={open ? {zIndex: 'auto'} : {}}>
						<Day
							dayNumber={dayNumber}
							alt={alt}
							info={info}
							width="1080"
							height="1080"
						/>
					</div>
				</Reward>
			</div>
		);
	}

	return null;
}
